// src/components/components-home/ActivationForm.js
import React, { useState } from "react";
import Modal from "./Modal";
import "../../assets/css/Global.css";
import "../../assets/css/VerticalStepperComponent.css";
import "./ActivationForm.css"; // Import the new CSS file for styling
import { useNavigate } from "react-router-dom";
import { isAndroid, isIOS } from "react-device-detect";
import appleWalletButton from "../../assets/wallet-buttons/en-apple.svg";
import googleWalletButton from "../../assets/wallet-buttons/en-google.svg";

const ActivationForm = () => {
  const [last4SSN, setLast4SSN] = useState("");
  const [dob, setDob] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const navigate = useNavigate();

  const memberBaseUrl = process.env.REACT_APP_MEMBER_BASE_URL;
  const wsBaseUrl = process.env.REACT_APP_WS_BASE_URL;
  const walletBaseUrl = process.env.REACT_APP_WALLET_BASE_URL;
  const oauthBaseUrl = process.env.REACT_APP_OAUTH_BASE_URL;
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL;

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const handleWalletCard = async () => {
    if (!last4SSN || !dob) {
      setError("Please fill in both fields.");
      return;
    }

    // Clear previous messages
    setError(null);
    setMessage(null);
    setIsModalOpen(false);
    setModalContent("");

    const formattedDob = formatDate(dob);

    async function fetchWithErrorHandling(url, options) {
      try {
        const response = await fetch(url, options);
        const contentType = response.headers.get("Content-Type");

        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          return data;
        } else if (contentType && contentType.includes("application/pdf")) {
          const blob = await response.blob();
          // Handle the PDF blob as needed, e.g., save it or display it
          return blob;
        } else {
          throw new Error(`Unsupported content type: ${contentType}`);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        throw error;
      }
    }

    const getToken = async (clientId, clientSecret) => {
      const tokenResponse = await fetchWithErrorHandling(
        `${oauthBaseUrl}/api/v1/oauth/token`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            grant_type: "client_credentials",
            client_id: clientId,
            client_secret: clientSecret,
          }),
        }
      );
      return tokenResponse.access_token.token;
    };

    const handleAndroid = async () => {
      const clientId = "2f07efe0-5d34-44fc-9a2e-93d61510d463";
      const clientSecret = "693297222160db64c9611f44643441fd";

      const memberCreds = await fetchWithErrorHandling(
        `${memberBaseUrl}/api/v1/members/getMemberCreds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            userType: "admin",
            dob: formattedDob,
            ssn: last4SSN,
          },
        }
      );

      const passId = memberCreds.memberCreds.pass_id;
      const token = await getToken(clientId, clientSecret);

      const memberDetails = await fetchWithErrorHandling(
        `${memberBaseUrl}/api/v1/members/${passId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // if (memberDetails.memberDetails.member_status === "inactive") {
      //   const googleObjectData = await fetchWithErrorHandling(
      //     "https://wallet.uat.cxserver.app/api/pass/v1/generateGoogleObject",
      //     {
      //       method: "POST",
      //       headers: { "Content-Type": "application/json" },
      //       body: JSON.stringify({
      //         member_id: memberDetails.memberDetails.entity_id,
      //         firstName: memberDetails.memberDetails.first_name,
      //         lastName: memberDetails.memberDetails.last_name,
      //         memberStatus: memberDetails.memberDetails.member_status,
      //       }),
      //     }
      //   );
      //   window.location.href = googleObjectData.link;
      //   return;
      // }

      const pdfUrlMetadata = memberDetails.metadata?.find(
        (meta) => meta.key === "pdf_url"
      );

      if (!pdfUrlMetadata) {
        await fetchWithErrorHandling(
          `${wsBaseUrl}/api/microsite/v1/createPdf`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              entity_id: memberDetails.memberDetails.entity_id,
              first_name: memberDetails.memberDetails.first_name,
              last_name: memberDetails.memberDetails.last_name,
              template_name: "HARLAN_FOODS",
              userType: "member",
            }),
          }
        );

        const updatedMemberDetails = await fetchWithErrorHandling(
          `${memberBaseUrl}/api/v1/members/${passId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const updatedPdfUrlMetadata = updatedMemberDetails.metadata.find(
          (meta) => meta.key === "pdf_url"
        );

        if (!updatedPdfUrlMetadata) {
          throw new Error("PDF URL not found");
        }

        const result = await fetchWithErrorHandling(
          `${walletBaseUrl}/api/pass/v1/generateGoogleObject`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              member_id: updatedMemberDetails.memberDetails.entity_id,
              firstName: updatedMemberDetails.memberDetails.first_name,
              lastName: updatedMemberDetails.memberDetails.last_name,
              memberStatus: "active",
              pdfUrl: `${s3BaseUrl}/${updatedPdfUrlMetadata.value}`,
            }),
          }
        );
        window.location.href = result.link;
      } else {
        const result = await fetchWithErrorHandling(
          `${walletBaseUrl}/api/pass/v1/generateGoogleObject`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              member_id: memberDetails.memberDetails.entity_id,
              firstName: memberDetails.memberDetails.first_name,
              lastName: memberDetails.memberDetails.last_name,
              memberStatus: "active",
              pdfUrl: `${s3BaseUrl}/${pdfUrlMetadata.value}`,
            }),
          }
        );
        window.location.href = result.link;
      }
    };

    const handleIOS = async () => {
      const response = await fetch(`${memberBaseUrl}/api/v1/metadata/cardUrl`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          dob: formattedDob,
          ssn: last4SSN,
        },
      });

      if (!response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const result = await response.json();
          setModalContent(
            result.message || "An error occurred. Please try again later."
          );
        } else {
          setModalContent("An error occurred. Please try again later.");
        }
        setIsModalOpen(true);
        return;
      }

      const result = await response.json();
      window.location.href = `${s3BaseUrl}/${result.link}`;
    };

    try {
      if (isAndroid) {
        await handleAndroid();
      } else if (isIOS) {
        await handleIOS();
      }
    } catch (error) {
      console.error("Error:", error);
      setModalContent("An error occurred. Please try again later.");
      setIsModalOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!last4SSN || !dob) {
      setError("Please fill in both fields.");
      return;
    }

    // Clear previous messages
    setError(null);
    setMessage(null);
    setIsModalOpen(false);
    setModalContent("");

    try {
      const formattedDob = formatDate(dob);

      const response = await fetch(
        `${memberBaseUrl}/api/v1/members/getMemberCreds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            userType: "admin",
            dob: formattedDob,
            ssn: last4SSN,
          },
        }
      );

      if (!response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const result = await response.json();
          if (response.status === 404) {
            setModalContent(
              "Record not found. Please call H at (123) 456-7890."
            );
          } else {
            setModalContent(
              result.message || "An error occurred. Please try again later."
            );
          }
        } else {
          setModalContent("An error occurred. Please try again later.");
        }
        setIsModalOpen(true);
        return;
      }

      const result = await response.json();
      setMessage(result.message || "Form submitted successfully");
      navigate(`/Harlan/${result.memberCreds.pass_id}`);
    } catch (err) {
      setModalContent("An error occurred. Please try again later.");
      setIsModalOpen(true);
    }
  };

  return (
    <div className="activation-form-page">
      <div className="activation-form-container">
        <h3 className="paragraph-text">
          Welcome to Your 2024 - 2025 Health Benefits
        </h3>
        <p>
          Please enter your last 4 SSN (or TIN) and date of birth to proceed.
        </p>
        {/* <form onSubmit={handleSubmit} className="activation-form"> */}
        <form className="activation-form">
          <div className="form-group">
            <label htmlFor="last4SSN">Last 4 SSN (or TIN):</label>
            <input
              type="text"
              id="last4SSN"
              value={last4SSN}
              onChange={(e) => setLast4SSN(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="dob">Date of Birth:</label>
            <input
              type="date"
              id="dob"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </div>
          <div className="action-container">
            {isIOS && (
              <div onClick={handleWalletCard} className="wallet-button">
                <img src={appleWalletButton} alt="Add to Apple Wallet" />
              </div>
            )}
            {isAndroid && (
              <div onClick={handleWalletCard} className="wallet-button">
                <img src={googleWalletButton} alt="Add to Google Wallet" />
              </div>
            )}
            {/* <button
              type="button"
              onClick={handleWalletCard}
              className="submit-button"
            >
              Click here first to add your Benefits card.
            </button> */}
            {/* <button type="submit" className="submit-button">
              {isIOS || isAndroid
                ? "Submit"
                : "Alternate entry to Enrollment portal."}
            </button> */}
          </div>
        </form>
        {error && <p className="error">{error}</p>}
        {message && <p className="message">{message}</p>}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={{ type: "info", message: modalContent }}
      />
    </div>
  );
};

export default ActivationForm;
